// utils/Notification.js

export default function notification(header, message, type = 'normal', showNotification = false) {
    if (!showNotification || typeof window === 'undefined') {
      return;
    }
  
    let bgColorClass = '';
  
    switch (type) {
      case 'success':
        bgColorClass = 'bg-green-500';
        break;
      case 'error':
        bgColorClass = 'bg-red-500';
        break;
      default:
        bgColorClass = 'bg-blue-500';
    }
  
    const notificationHTML = `
      <div class="fixed z-50 transform translate-y-full right-5 text-xs bg-gray-800 text-white p-2 rounded shadow-md ${bgColorClass} notification">
        <div class="flex items-center justify-between">
          <div class="ml-2">
            <div class="font-bold">${header}</div>
            <div>${message}</div>
          </div>
        </div>
      </div>
    `;
  
    document.body.insertAdjacentHTML('beforeend', notificationHTML);
  
    let topPosition = 0;
    const existingNotifications = document.querySelectorAll('.notification');
    existingNotifications.forEach((existingNotification) => {
      topPosition += existingNotification.offsetHeight + 10;
    });
  
    const notification = document.body.lastElementChild;
    notification.style.top = `${topPosition}px`;
  
    setTimeout(() => {
      notification.style.transform = 'translateY(0%)';
      setTimeout(() => {
        notification.style.transform = 'translateY(-100%)';
        setTimeout(() => {
          document.body.removeChild(notification);
        }, 300);
      }, 3000); // Show notification for 3 seconds before hiding
    }, 10);
  }
  